export default class Capabilities {
    static get STORAGE_KEY() {
        return 'selected-capabilities';
    }

    constructor() {
        /** @type {string[]} */
        this.selectedCapabilities = JSON.parse(localStorage.getItem(Capabilities.STORAGE_KEY)) || [];
        /** @type {HTMLFormElement} */
        this.form = document.querySelector('form#capabilities-form');
        /** @type {HTMLInputElement} */
        this.capabilitiesInput = this.form.querySelector('input[name="capabilities"]');

        this.updateInput();

        this.form.addEventListener('submit', () => {
            this.selectedCapabilities = [];
            this.save();
        });

        Array.from(this.form.querySelectorAll('input[type="checkbox"]'))
            .forEach(checkbox => {
                checkbox.checked = this.selectedCapabilities.includes(checkbox.value);
                checkbox.addEventListener('change', () => this.onCheckboxChange(checkbox));
            });
    }

    /**
     * @param {HTMLInputElement} checkbox
     */
    onCheckboxChange(checkbox) {
        if (checkbox.checked) {
            this.selectedCapabilities.push(checkbox.value);
        } else {
            this.selectedCapabilities.splice(this.selectedCapabilities.indexOf(checkbox.value), 1);
        }
        this.save();
        this.updateInput();
    }

    save() {
        localStorage.setItem(Capabilities.STORAGE_KEY, JSON.stringify(this.selectedCapabilities));
    }

    updateInput() {
        this.capabilitiesInput.value = this.selectedCapabilities.join(',');
    }
}
