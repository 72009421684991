import objectFitImages from 'object-fit-images';
import 'simplebar';
import WebFont from 'webfontloader';

import AOS from 'aos';
import 'aos/dist/aos.css';

import * as basicScroll from 'basicscroll';

import Nav from './nav';
import Capabilities from './renderers/capabilities';
import Contact from './renderers/contact';
import Home from './renderers/home';
import Metier from './renderers/metiers';
import Rte from './renderers/rte';

objectFitImages();

WebFont.load({
    google: {
        families: ['IBM Plex Serif:300,300i,400,500,700', 'IBM Plex Sans:200,200i,300,500,500i'],
    },
});

window.disableAOS = () => {
    const botPattern =
        '(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
    const re = new RegExp(botPattern, 'i');
    return re.test(navigator.userAgent) || window.outerWidth < 768;
};
window.isMobileDevice = () => window.orientation !== undefined || navigator.userAgent.indexOf('IEMobile') !== -1;

document.addEventListener('DOMContentLoaded', () => {
    new Nav();

    // ?? AOS
    if (window.disableAOS) {
        document.body.parentNode.classList.add('no-aos');
    } else {
        document.body.parentNode.classList.remove('no-aos');
    }
    if (!document.body.classList.contains('aos-processed') && AOS && (window.innerWidth >= 1120)) {
        AOS.init({
            // offset: 100,
            // duration: 1000,
            // easing: 'ease-out',
            delay: 0,
            once: false,
            // disable: 'mobile',
            disable: window.disableAOS,
        });
        if (disableAOS()) {
            document.body.parentNode.classList.add('no-aos');
        } else {
            document.body.parentNode.classList.remove('no-aos');
            setTimeout(() => {
                AOS.refresh();
            }, 500);
        }
    }

    // ?? Smooth scroll
    /*var smoothScrollSpeed = 750; // Durée de l'animation (en ms)
    $(document).on('click', 'a[href*="#"]', function(e) { // Au clic sur un élément
        console.log(e);
        var href = $(e.currentTarget).attr('href'); // Page cible
        if (href.indexOf('#') > -1) {
            var anchor = href.indexOf('#') === 0 ? href : '#' + href.split('#')[1];
            if ((href.indexOf('#') === 0 || window.location.pathname === href.split('#')[0] || window.location.protocol + '//' + window.location.hostname + window.location.pathname === href.split('#')[0]) && $(anchor).length) {
                e.preventDefault();
                $('html, body').animate( { scrollTop: $(anchor).offset().top - 115 }, smoothScrollSpeed ); // Go
                return false;
            }
        }
    });
    if (window.location.hash && window.location.hash !== '#' && $(window.location.hash).length) {
        setTimeout(function(){
            $('html, body').animate( { scrollTop: $(window.location.hash).offset().top - 115 }, smoothScrollSpeed );
        }, 1000);
    }*/

    const links = document.querySelectorAll("a[href*='#']");
    for (const link of links) {
        link.addEventListener("click", clickHandler);
    }

    function clickHandler(e) {
        e.preventDefault();
        const href = this.getAttribute("href");
        // const offsetTop = document.querySelector(href).offsetTop - 20;
        const offsetTop = document.querySelector(href).getBoundingClientRect().top + window.scrollY - 80;

        scroll({
            top: offsetTop,
            behavior: "smooth"
        });
    }

    // ?? Parallax sur les pages comprises dans la condition
    if ((document.body.classList.contains('home') || document.body.classList.contains('category-actualites') || document.body.classList.contains('category-news') || document.body.classList.contains('page-template-expertise') || document.body.classList.contains('page-template-contact')) && window.innerWidth >= 1070) {
        const prllxs_v = document.querySelectorAll('.prllx-v:not(.prllx-processed)');
        prllxs_v.forEach((prllx_v) => {
            const instanceV = basicScroll.create({
                elem: prllx_v,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--translateY': {
                        from: '0',
                        to: '30%',
                    },
                },
            });
            instanceV.start();
            prllx_v.classList.add('prllx-processed');
        });

        const prllxs_h = document.querySelectorAll('.prllx-h:not(.prllx-processed)');
        prllxs_h.forEach((prllx_h) => {
            const instanceH = basicScroll.create({
                elem: prllx_h,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--translateX': {
                        from: '0',
                        to: '10%',
                    },
                },
            });
            instanceH.start();
            prllx_h.classList.add('prllx-processed');
        });
    }

    // ?? Scripts
    if (document.body.classList.contains('home')) {
        new Home();
    }

    if (document.body.classList.contains('page') && !document.body.classList.contains('home') && !document.body.classList.contains('page-template-contact') && !document.body.classList.contains('page-template-metier')) {
        new Rte();
    }

    if (document.body.classList.contains('page-template-contact') && !document.querySelector('form .validate')) {
        new Contact();
    }

    if (document.body.classList.contains('page-template-metiers')) {
        new Metier();
    }

    if (document.body.classList.contains('post-type-archive-capability')) {
        new Capabilities();
    }

});