import Swiper from 'swiper';

export default class Home {
    constructor() {
        // Page d'accueil
        this.sliderTemoignages();
        let sliderActusF = this.sliderActus();
        let sliderCertificationF = this.sliderCertifications();


        /* On Load */
        window.addEventListener('load', function() {
            sliderCertificationF;
            sliderActusF;
            // console.log('load');
            if (window.innerWidth >= 1120) {
                // instance.start();
            }
        });

        /* On Resize */
        window.addEventListener('resize', function() {
            sliderCertificationF;
            sliderActusF;
            // console.log('resize');
        });

        // document.querySelector('.pushs .scroll').addEventListener('click', () => {
        //     window.scrollTo({
        //         top: window.innerHeight,
        //         behavior: 'smooth',
        //     });
        // });
    }

    sliderActus() {

        var init = false;
        var sliderActualites = Swiper;

        let desktop = (window.innerWidth >= 1120);
        // console.log(window.innerWidth);

        if (!init && !desktop) {
            init = true;
            let sliderActualites = new Swiper('.slider-actualites', {
                loop: true,
                navigation: {
                    nextEl: '.slider-actualites .controls .next',
                    prevEl: '.slider-actualites .controls .prev',
                },
                centeredSlides: true,
                slidesPerView: 'auto',
                speed: 850
            });
        }

        // console.log(desktop);
        if (desktop) {
            init = false;
            // console.log('disabled');
        } else {
            init = true;
            // console.log('enabled');
        }



    }

    sliderTemoignages() {
        let sliderTemoignages = new Swiper('.slider-temoignages', {
            loop: true,
            navigation: {
                nextEl: '.temoignages .slider-temoignages .controls .next',
                prevEl: '.temoignages .slider-temoignages .controls .prev',
            },
            speed: 650,
            autoplay: {
                delay: 6000
            }
        });
    }
    sliderCertifications() {
        var init = false;
        var swiperCertifications = Swiper;

        // let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
        // let tablet = window.matchMedia('(min-width: 769px) and (max-width: 1024px)');
        let desktop = window.matchMedia('(min-width: 1025px)');


        if (!init) {
            init = true;
            swiperCertifications = new Swiper('.slider-certifications', {
                loop: true,
                slidesPerView: 2,
                spaceBetween: 10,
                navigation: {
                    nextEl: '.certifications .slider-certifications .controls .next',
                    prevEl: '.certifications .slider-certifications .controls .prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
                // pagination: true,
                speed: 650,
                breakpoints: {
                    700: {
                        slidesPerView: 3,
                    },
                    768: {
                        loop: false,
                        slidesPerView: 6,
                        spaceBetween: 20,
                        preloadImages: false,
                        lazy: true,
                        pagination: false,
                        // setWrapperSize: true,
                        roundLengths: true,
                        // centeredSlides: true,
                    },
                    // 640: { },
                },
            });
            // console.log('slider initialized');
        }

        // Disable (for tablet)
        // if(tablet.matches || desktop.matches) {
        if (desktop.matches) {
            swiperCertifications.disable();
            init = false;
            // console.log('disabled');
        } else {
            swiperCertifications.enable();
            init = true;
            // console.log('enabled');
        }
    }
}