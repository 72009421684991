import Choices from 'choices.js';
// import mapStyles from '../map-styles.json';

export default class Contact {

    constructor() {

        this.choices = new Choices('[data-choice]', {
            renderChoiceLimit: 5,
            placeholderValue: '',
            duplicateItemsAllowed: false,
            removeItemButton: true,
        });

        this.initForm();
    }

    initForm() {
        /** @type {HTMLFormElement} */
        this.form = document.querySelector('.form form');
        /** @type {HTMLElement[]} */
        this.formParts = Array.from(this.form.querySelectorAll('.form .form-detail'));
        /** @type {HTMLElement} */
        this.formFooter = this.form.querySelector('.form-footer');
        /** @type {HTMLSelectElement} */
        this.typeSelect = this.form.querySelector('select#type');

        if (!this.typeSelect) {
            return;
        }

        Array.from(document.querySelectorAll('input[type="file"]'))
            .forEach(input => {
                const label = input.previousElementSibling;
                const originalTitle = label.dataset.title;
                input.addEventListener('change', () => {
                    label.dataset.title = input.files.length ?
                        Array.from(input.files).map(file => file.name).join(', ') :
                        originalTitle;
                });
            });

        /**
         * Holds select old value
         * @type {string}
         */
        this.type = this.typeSelect.value;

        this.inactiveForms.forEach(form => form.remove());
        this.formFooter.classList.toggle('hidden', !this.activeForm);

        this.typeSelect.addEventListener('change', () => {
            // Remove old form
            if (this.activeForm) {
                this.activeForm.remove();
            }

            // Update to new value
            this.type = this.typeSelect.value;

            // Append new form
            if (this.activeForm) {
                this.form.insertBefore(this.activeForm, this.formFooter);
            }
            this.formFooter.classList.toggle('hidden', !this.activeForm);
        });
    }

    /**
     * @return {HTMLElement}
     */
    get activeForm() {
        return this.formParts.find(form => form.dataset.form === this.type);
    }

    /**
     * @return {HTMLElement[]}
     */
    get inactiveForms() {
        return this.formParts.filter(form => form.dataset.form !== this.type);
    }

    /*initMap() {
        let mapElement = document.querySelector('section .map-container .map');
        let center = JSON.parse(mapElement.dataset.position);
        let map = new google.maps.Map(mapElement, {
            center,
            zoom: 6,
            styles: mapStyles,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
        });

        new google.maps.Marker({
            map,
            position: center,
            icon: {
                anchor: new google.maps.Point(12, 34),
                url: require('../../img/marker.png'),
            },
        });
    }*/

}