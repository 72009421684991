export default class Nav {
    constructor() {

        // Nav
        this.lastScroll = window.pageYOffset;
        const rechercheInput = document.querySelector('.recherche form fieldset input');
        if (rechercheInput) {
            rechercheInput.addEventListener('input', this.autocomplete);
        }
        let capabilitiesSearch = document.querySelector('nav .capabilities-link');
        let capabilitiesSearchAside = document.querySelector('.conteneur.recherche-aside');
        if ((capabilitiesSearch && capabilitiesSearchAside) && (window.innerWidth >= 1020 && !(isMobileDevice()))) {
            capabilitiesSearch.appendChild(capabilitiesSearchAside);
        } else if (capabilitiesSearch && (window.innerWidth < 1020 || isMobileDevice())) {
            // capabilitiesSearch.firstChild.setAttribute('href','/');
            capabilitiesSearch.classList.remove('no-link');
            if (capabilitiesSearchAside) { capabilitiesSearchAside.remove(); }
        } else if (!(capabilitiesSearch)) {
            if (capabilitiesSearchAside) { capabilitiesSearchAside.remove(); }
        }

        let autocompleteOverlay = document.querySelector('.recherche form fieldset .overlay');
        if (autocompleteOverlay) {
            autocompleteOverlay.addEventListener('click', this.closeAutocomplete);
        }

        let autocompleteClose = document.querySelector('.recherche .close');
        if (autocompleteClose) {
            autocompleteClose.addEventListener('click', this.toggleSearch);
        }
        // console.log(window.innerWidth);
        if (window.innerWidth >= 1020 && !(isMobileDevice())) {
            [].forEach.call(document.querySelectorAll('nav .header-main .menu-item > .sub-menu'), (el) => {
                // ?? Ajoute classes level 2 et 3
                if (el.parentNode.classList.contains('menu-item-has-children')) {
                    el.classList.add('level-2');
                }
                const lvl3Ok = el.querySelector('.menu-item > .sub-menu');
                if (lvl3Ok) { lvl3Ok.classList.remove('level-2');
                    lvl3Ok.classList.add('level-3'); }
                // ?? Wrapper .conteneur autour de la liste 
                let org_html = el.outerHTML;
                let new_html = "<div class='conteneur'>" + org_html + "</div>";
                el.outerHTML = new_html;
            });
        }

        /* //Change la hauteur du menu lors de l'affichage du niveau 3
        $('#menu-principal .level-2 .menu-item-has-children').hover(function(){
            var hauteur = $(this).children('.level-3').children('.conteneur').height();
            var hauteur_2 = $(this).parents('.level-2').height();
            if( hauteur_2 <= hauteur) {
                $('#menu-principal .level-2').height(hauteur);
            }
        },function(){
            $('#menu-principal .level-2').height('auto');
        });*/



        document.querySelector('nav .burger').addEventListener('click', this.toggleMobile);

        let getSubNav = document.querySelectorAll('nav .nav ul .menu-item-has-children > a');
        for (let i = 0; i < getSubNav.length; i++) {
            getSubNav[i].addEventListener('click', this.toggleSubNav);
        }

        const getNav = document.querySelector('nav.row');
        let normalLogo = document.querySelector('nav.row .logo a img#normal-logo');
        let miniLogo = document.querySelector('nav.row .logo a img#mini-logo');

        Array.from(document.querySelectorAll('section.row .wp-block-media-text')).forEach(element => {
            element.classList.toggle('visible', window.pageYOffset > (this.getElementOffset(element).top - window.innerHeight / 2));
        });

        window.addEventListener('scroll', () => {
            getNav.classList.toggle('small', window.pageYOffset > 0);
            //getNav.classList.toggle('hidden', this.lastScroll < window.pageYOffset && window.pageYOffset > 0);

            this.lastScroll = window.pageYOffset;

            Array.from(document.querySelectorAll('section.row .wp-block-media-text')).forEach(element => {
                element.classList.toggle('visible', window.pageYOffset > (this.getElementOffset(element).top - window.innerHeight / 2));
            });
        }, { passive: true });
    }

    toggleSearch() {
        document.querySelector('aside.recherche').classList.toggle('visible');
        document.querySelector('html').classList.toggle('overflow');
    }

    autocomplete(e) {
        const target = e.currentTarget;
        if (target.value.length >= 2) {
            const url = AirSupport.adminUrl + '?action=capabilities_autocomplete&q=' + target.value;
            let xmlhttp = new XMLHttpRequest();
            xmlhttp.open('GET', url, true);
            xmlhttp.onreadystatechange = () => {
                if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
                    const data = JSON.parse(xmlhttp.responseText);
                    fillSuggest(data);
                }
            };
            xmlhttp.send();
        }

        function fillSuggest(response) {
            const container = document.querySelector('.recherche form fieldset .autocomplete');
            container.style.display = 'block';
            container.parentNode.querySelector('.overlay').style.display = 'block';

            let content;

            // ATA
            content = '<h3>ATA</h3><ul>';
            for (let i in response.ata_numbers) {
                content += '<li>' + response.ata_numbers[i] + '</li>';
            }
            content += '</ul>';

            // Description
            content += '<h3>Description</h3><ul>';
            for (let j in response.description) {
                content += '<li>' + response.description[j] + '</li>';
            }
            content += '</ul>';

            // Part number
            content += '<h3>Part number</h3><ul>';
            for (let k in response.part_number) {
                content += '<li>' + response.part_number[k] + '</li>';
            }
            content += '</ul>';

            container.innerHTML = content;

            const getLi = document.querySelectorAll('.recherche form fieldset .autocomplete ul li');
            for (let l = 0; l < getLi.length; l++) {
                getLi[l].addEventListener('click', (e) => {
                    document.querySelector('.recherche form fieldset input').value = e.currentTarget.innerText;
                    document.querySelector('.recherche form').submit();
                });
            }
        }
    }

    closeAutocomplete() {
        document.querySelector('.recherche form fieldset .autocomplete').style.display = 'none';
        document.querySelector('.recherche form fieldset .autocomplete').innerHTML = '';
        document.querySelector('.recherche form fieldset .overlay').style.display = 'none';
    }

    toggleMobile() {
        document.querySelector('nav .burger').classList.toggle('open');
        document.querySelector('nav .nav').classList.toggle('visible');
        document.querySelector('html').classList.toggle('overflow');
    }

    toggleSubNav(e) {
        e.preventDefault();
        e.currentTarget.classList.toggle('open');
        e.currentTarget.nextElementSibling.classList.toggle('visible');
    }

    getElementOffset(el) {
        const rect = el.getBoundingClientRect();

        return {
            top: rect.top + window.pageYOffset,
            left: rect.left + window.pageXOffset,
        };
    }
}