import Swiper from 'swiper';

export default class Rte {
    constructor() {
        // Page RTE
        this.sliderRte();
        this.sliderEngagements();
        this.sliderTemoignages();
        this.sliderTimeline();
        this.modals();
        // this.addDeco();
        for (let index = 0; index < document.querySelectorAll('.slider-card').length; index++) {
            this.sliderCards(index);
        }
        Array.from(document.querySelectorAll('.video-container')).forEach(element => {
            element.querySelector('.play').addEventListener('click', () => this.video(element));
        });

        Array.from(document.querySelectorAll('.tabs-container ul li')).forEach(element => {
            element.addEventListener('click', this.manageTabs);
        });

        /* ---------- COLLAPSE (Accordion block content) ---------- */
        Array.from(document.querySelectorAll('.custom-collapse .collapse-button')).forEach(element => {
            element.addEventListener('click', () => this.toggleAccordeon(element));
        });
        /** GESTION DES COOKIES */
        var boutonGestionCookies = document.getElementById('bouton-cookies');
        if (boutonGestionCookies) {
            boutonGestionCookies.addEventListener('click', function() {
                openAxeptioCookies();
            });
        }
        /************************/

    }

    sliderEngagements() {
        let sliderEngagements = new Swiper('.slider-engagements', {
            mode: 'horizontal',
            loop: false,
            calculateHeight: true,
            slidesPerView: 1,
            spaceBetween: 10,
            // Responsive breakpoints
            breakpoints: {
                992: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 40
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    }
    sliderCards(i) {
        let sliderCards = new Swiper('.slider-card-'+i, {
            mode: 'horizontal',
            loop: false,
            calculateHeight: true,
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
                el: '.pagination-slider-card-'+i,
                type: 'bullets',
                clickable: true,
            },
        });
    }
    sliderRte() {
        let sliderRte = new Swiper('.slider-rte', {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            pagination: {
                el: '.pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    }

    sliderTemoignages() {
        let sliderTemoignages = new Swiper('.slider-temoignages', {
            loop: true,
            navigation: {
                nextEl: '.temoignages-container .slider-temoignages .controls .next',
                prevEl: '.temoignages-container .slider-temoignages .controls .prev',
            },
        });
    }
    modals() {
        let modalsToggle = document.querySelectorAll('[data-bs-toggle="modal"]');
        modalsToggle.forEach(el => {
           const modal = document.getElementById(el.getAttribute('data-bs-target'));
           const bgModal = document.getElementById('bg-' + el.getAttribute('data-bs-target'));
            el.addEventListener('click', (e) => {
                modal.classList.toggle('open');
                bgModal.classList.toggle('open');
                document.getElementsByTagName( 'html' )[0].classList.toggle('modal-open');
            });
            bgModal.addEventListener('click', (e) => {
                modal.classList.remove('open');
                bgModal.classList.remove('open');
                document.getElementsByTagName( 'html' )[0].classList.remove('modal-open');
            });
        });
    }
    // addDeco() {
    //     if ((document.getElementsByClassName('deco-rejoindre').length > 0 && document.getElementsByClassName('page-template-rejoindre').length > 0) && window.innerWidth >= 992) {
    //         let heightM = document.getElementsByClassName('main row-1400')[0].offsetHeight;
    //         const nbicon = Math.round(heightM / 1500) < 5 ? Math.round(heightM / 1500) : 5;
    //         const element = ['ring', 'pilps-blue', 'pilps-blue-rotate', 'pilps-blue-right', 'pilps-blue-left'];
    //         const decos = document.getElementsByClassName('deco-rejoindre')[0];
    //         for (let i = 0; i < nbicon; i++) {
    //             const side = i%2 == 0 ? 'left' : 'right';
    //             const img = document.createElement('img');
    //             img.src = '/app/themes/air-support/assets/img/decoratifs/element-'+element[Math.floor(Math.random() * 4)]+'.png';
    //             img.classList.add('prllx-v', side);
    //             decos.appendChild(img);
    //         }
    //     }
    // }
    /**
     * @param {HTMLElement} videoContainer
     */
    video(videoContainer) {
        videoContainer.innerHTML += `
            <iframe src="${videoContainer.dataset.src}"
                    class="player"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
            </iframe>
        `;
    }

    manageTabs(elt, index) {
        const tabContainer = elt.currentTarget.parentElement;
        let getIndex = Array.from(tabContainer.children).indexOf(elt.currentTarget);
        for (let k = 0; k < tabContainer.children.length; k++) {
            tabContainer.children[k].classList.remove('active');
            tabContainer.parentNode.querySelector('.tabs').children[k].style.display = 'none';
        }
        elt.currentTarget.className += ' active';
        tabContainer.parentNode.querySelector('.tabs').children[getIndex].style.display = 'block';
    }

    sliderTimeline() {
        let nbSlides = 4;
        if (window.innerWidth < 768) {
            nbSlides = 1;
        }
        let sliderTimeline = new Swiper('.slider-timeline', {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            slidesPerView: nbSlides,
            centeredSlides: true,
            navigation: {
                prevEl: '.timeline-container .controls .prev',
                nextEl: '.timeline-container .controls .next',
            },
        });
    }

    toggleAccordeon(accordionElement) {
        accordionElement.classList.toggle('active');
        // accordionElement.nextElementSibling.classList.toggle('visible');

        let slideUp = (target, duration = 500) => {
            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.boxSizing = 'border-box';
            target.style.height = target.offsetHeight + 'px';
            target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            window.setTimeout(() => {
                target.style.display = 'none';
                target.style.removeProperty('height');
                target.style.removeProperty('padding-top');
                target.style.removeProperty('padding-bottom');
                target.style.removeProperty('margin-top');
                target.style.removeProperty('margin-bottom');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
                //alert("!");
            }, duration);
        }

        let slideDown = (target, duration = 500) => {
            target.style.removeProperty('display');
            let display = window.getComputedStyle(target).display;

            if (display === 'none')
                display = 'flex';

            target.style.display = display;
            let height = target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.offsetHeight;
            target.style.boxSizing = 'border-box';
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + 'ms';
            target.style.height = height + 'px';
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            window.setTimeout(() => {
                target.style.removeProperty('height');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        }
        let slideToggle = (target, duration = 500) => {
            if (window.getComputedStyle(target).display === 'none') {
                return slideDown(target, duration);
            } else {
                return slideUp(target, duration);
            }
        }

        slideToggle(accordionElement.nextElementSibling, 200);
    }
}