import Swiper from 'swiper';

export default class Metier {
    constructor() {
        // Page metiers

        Array.from(document.querySelectorAll('section .savoir-faire .savoir-container .savoir .wrapper'))
            .forEach(elt => elt.addEventListener('click', this.toggleDetail));

        Array.from(document.querySelectorAll('section .savoir-faire .savoir-container .savoir .img'))
            .forEach(elt => elt.addEventListener('click', this.toggleDetail));

        Array.from(document.querySelectorAll('section .savoir-faire .savoir-content .up'))
            .forEach(elt => elt.addEventListener('click', this.closeDetail));

        // Wrap each keyword in a span element
        Array.from(document.querySelectorAll('.wp-caption .wp-caption-text'))
            .forEach(caption => {
                caption.innerHTML = caption.innerText.split(';').map(keyword => `<span data-text="${keyword}"></span>`).join('');
            });

        if (document.querySelector('.savoir-faire .savoir-content .accordion')) {
            Array.from(document.querySelectorAll('.savoir-faire .savoir-content .accordion h4')).forEach(elt => {
                elt.addEventListener('click', (e) => {
                    e.currentTarget.classList.toggle('open');
                });
            });
        }
    }

    toggleDetail(elt) {
        if (!elt.currentTarget.parentNode.classList.contains('active')) {
            const currentElement = elt.currentTarget.parentNode.children[1].children[2].dataset.target;
            elt.currentTarget.parentNode.classList.add('active');
            const getContent = document.querySelectorAll('section .savoir-faire .savoir-content');
            for (let j = 0; j < getContent.length; j++) {
                getContent[j].style.display = 'none';
            }
            document.querySelector('section .savoir-faire .savoir-content.' + currentElement).style.display = 'block';

            const getScroll = getElementOffset(document.querySelector('section .savoir-faire .savoir-container')).top + document.querySelector('section .savoir-faire .savoir-container').offsetHeight;
            window.scrollTo(0, getScroll);

            if (document.querySelector('.slider-savoir-detail')) {
                Array.from(document.querySelectorAll('section .savoir-faire .savoir-content.' + currentElement + ' .slider-savoir-detail')).forEach(e => {
                    let sliderSavoir = new Swiper(e, {
                        loop: true,
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                        },
                        autoplay: {
                            delay: 2000,
                        }
                    });
                });
            }

            window.addEventListener('scroll', () => {
                // console.log('test');
                Array.from(document.querySelectorAll('.page-template-metiers section.row .savoir-faire .savoir-content.' + currentElement + ' > *')).forEach(element => {
                    // console.log(element);
                    element.classList.toggle('visible', window.pageYOffset > (getElementOffset(element).top - window.innerHeight / 1.25));
                });
            }, { passive: true });
        } else {
            elt.currentTarget.parentNode.classList.remove('active');
            document.querySelector('section .savoir-faire .savoir-content.' + elt.currentTarget.parentNode.children[1].children[2].dataset.target).style.display = 'none';
        }

        function getElementOffset(el) {
            const rect = el.getBoundingClientRect();

            return {
                top: rect.top + window.pageYOffset,
                left: rect.left + window.pageXOffset,
            };
        }
    }

    closeDetail(elt) {
        elt.currentTarget.parentNode.style.display = 'none';
        const getContainer = document.querySelectorAll('section .savoir-faire .savoir-container .savoir');
        for (let l = 0; l < getContainer.length; l++) {
            getContainer[l].classList.remove('active');
        }
        window.scrollTo({
            top: document.querySelector('section .savoir-faire .savoir-container').offsetTop + window.innerHeight,
            behavior: 'smooth'
        });
    }
}